var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var C1,Tpa,L1,M1,O1,Upa,Vpa,Wpa,Xpa,Ypa,$pa,aqa,bqa,Zpa,R1,S1;C1=function(a,b){var c=$CLJS.Bd,d=B1;for(a=$CLJS.y(a);;)if(null!=a){d=$CLJS.J.j(d,$CLJS.z(a),c);if(c===d)return b;a=$CLJS.B(a)}else return d};
$CLJS.D1=function(a){return function(b){var c=$CLJS.Ve(-1);return function(){function d(l,m){var t=c.nd(null,c.Lb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
$CLJS.E1=function(a,b,c){return null==c||$CLJS.Cd(a,b)?a:$CLJS.R.j(a,b,c)};$CLJS.F1=function(a,b){return $CLJS.Wf.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,new $CLJS.h(null,1,[$CLJS.xD,$CLJS.p.h($CLJS.HD())],null)],null),$CLJS.cf.h($CLJS.qV),b)};$CLJS.G1=function(a,b){return $CLJS.wV(a)&&$CLJS.E.g($CLJS.z(a),b)};$CLJS.H1=function(a,b){a=$CLJS.DV(a,b);return 0<a?a-1:null};
$CLJS.I1=function(a,b){if($CLJS.y(b)){if($CLJS.E.g($CLJS.D(b),1))return $CLJS.z(b);a=[" ",(0,$CLJS.ma)(a)," "].join("");return $CLJS.E.g($CLJS.D(b),2)?[$CLJS.p.h($CLJS.z(b)),a,$CLJS.p.h($CLJS.dd(b))].join(""):[$CLJS.hs(", ",$CLJS.es(b)),",",a,$CLJS.p.h($CLJS.ed(b))].join("")}return null};$CLJS.J1=function(a){return $CLJS.NU.h($CLJS.z($CLJS.qK.h(a)))};
$CLJS.K1=function(a,b,c,d){a=$CLJS.CV(a);b=$CLJS.n(b)?b:-1;var e=$CLJS.EV(a,b);e=!($CLJS.y($CLJS.ZE.h(e))||$CLJS.y($CLJS.YE.h(e)));c=$CLJS.PV.l(a,b,$CLJS.Xj,$CLJS.H([c,function(f){return $CLJS.Yd.g($CLJS.yf(f),$CLJS.qV.h(d))}]));return e?$CLJS.Xj.M((0,$CLJS.PV)(c,b,function(f){return $CLJS.SV($CLJS.Nj.l(f,$CLJS.NQ,$CLJS.H([$CLJS.RD])),$CLJS.DM,function(k){return $CLJS.Wj.g(function(l){return $CLJS.Nj.g(l,$CLJS.RD)},k)})}),$CLJS.qK,$CLJS.Uj.g(function(f){return $CLJS.Wf.g($CLJS.xf,f)},$CLJS.bk),0,
$CLJS.DV(a,b)+1):c};Tpa=function(a,b){b=$CLJS.e_(a,b);return $CLJS.n(b)?$CLJS.n1(a,b):null};L1=function(a){return $CLJS.Wf.j($CLJS.Sg,$CLJS.Vj.h(function(b){return $CLJS.he(b)&&$CLJS.Tj.g(b,$CLJS.Vs)}),$CLJS.Ng(a))};M1=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.El(function(b,c){$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.xD);return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),$CLJS.ZE.h(a))};
$CLJS.N1=function(a,b,c){var d=$CLJS.mk.l;b=$CLJS.Ra($CLJS.H1(a,b))?function(){var f=$CLJS.J1(a);return $CLJS.n(f)?(f=Tpa(a,f),$CLJS.n(f)?$CLJS.rG(function(k){return $CLJS.E.g($CLJS.Ci.h(k),c)},f):null):null}():null;try{var e=$CLJS.Z0(a,c)}catch(f){e=null}return d.call($CLJS.mk,$CLJS.H([b,e]))};
O1=function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null),d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.tO);d=$CLJS.J.g(d,$CLJS.EM);$CLJS.I(a,2,null);a=$CLJS.n(d)?$CLJS.E.g(d,$CLJS.YJ.h(b)):d;return $CLJS.n(a)?a:$CLJS.E.g($CLJS.zz($CLJS.eZ,$CLJS.ZY)(b),c)};
Upa=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null),d=$CLJS.Be($CLJS.ff(function(e){var f=$CLJS.E.g($CLJS.KY.h(e),c);return f?O1(a,e):f},b));return $CLJS.n(d)?d:$CLJS.ff(function(e){var f=$CLJS.E.g($CLJS.U.h(e),c);return f?O1(a,e):f},b)};
Vpa=function(a,b,c){$CLJS.I(a,0,null);var d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.Be($CLJS.ff(function(k){var l=$CLJS.E.g($CLJS.Ci.h(k),e);if(l){if(l=$CLJS.Ra($CLJS.tO.h(d))){l=$CLJS.MJ.h(k);var m=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.OY,null,$CLJS.TY,null],null),null);l=m.h?m.h(l):m.call(null,l)}return $CLJS.n(l)?l:O1(a,k)}return l},b));if($CLJS.n(f))return f;b=$CLJS.n(c)?$CLJS.Be($CLJS.ff(function(k){return $CLJS.E.g($CLJS.Ci.h(k),e)},b)):null;return $CLJS.n(b)?b:$CLJS.xf};
Wpa=function(a){var b=$CLJS.P1.g($CLJS.MJ.h(a),$CLJS.EY);return $CLJS.n(b)?b:$CLJS.oD.h(a)};
Xpa=function(a,b){b=$CLJS.Be($CLJS.Vj.g($CLJS.YJ,b));if($CLJS.n(b))if($CLJS.Ra($CLJS.B(b)))a=$CLJS.z(b);else{if($CLJS.n($CLJS.P1.g($CLJS.z(a),$CLJS.jF))){var c=$CLJS.Be($CLJS.Vj.g(Wpa,b));c=$CLJS.n(c)?$CLJS.B(c)?null:$CLJS.z(c):null}else c=null;a=$CLJS.n(c)?c:console.warn($CLJS.Uh("Ambiguous match! Implement more logic in disambiguate-matches.",new $CLJS.h(null,2,[$CLJS.W,a,$CLJS.BV,b],null)))}else a=null;return a};
Ypa=function(a,b){b=$CLJS.Be($CLJS.Vj.g(function(c){var d=$CLJS.zz($CLJS.eZ,$CLJS.ZY)(c);$CLJS.n(d)?(c=$CLJS.MJ.h(c),d=new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.OY,null],null),null),c=d.h?d.h(c):d.call(null,c),c=$CLJS.Ra(c)):c=d;return c},b));return $CLJS.n(b)?$CLJS.Ra($CLJS.B(b))?$CLJS.z(b):Xpa(a,b):null};
$pa=function(a,b){var c=$CLJS.ED(a);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.tO);return $CLJS.n(d)?(b=$CLJS.Be($CLJS.ff(function(e){return $CLJS.E.g($CLJS.zz($CLJS.eZ,$CLJS.ZY)(e),d)},b)),$CLJS.n(b)?$CLJS.Ra($CLJS.B(b))?$CLJS.z(b):console.warn("Multiple plausible matches with the same :join-alias - more disambiguation needed",new $CLJS.h(null,2,[$CLJS.W,a,Zpa,b],null)):null):Ypa(a,b)};aqa=new $CLJS.M("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);
bqa=new $CLJS.M("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);$CLJS.Q1=new $CLJS.M(null,"generous?","generous?",1247836118);$CLJS.cqa=new $CLJS.M(null,"matching-refs","matching-refs",324693611);Zpa=new $CLJS.M(null,"matches","matches",635497998);R1=new $CLJS.M(null,"left","left",-399115937);S1=new $CLJS.M("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);$CLJS.P1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ki,$CLJS.gB],null),$CLJS.Ki,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.equality","\x3d"),function(f,k){f=$CLJS.XA(f);k=$CLJS.XA(k);return $CLJS.Tj.g(f,k)?S1:f},e,a,b,c,d)}();$CLJS.P1.m(null,S1,function(){return!1});
$CLJS.P1.m(null,$CLJS.pA,function(a,b){var c=L1(a),d=L1(b);return $CLJS.E.g(c,d)&&$CLJS.Le(function(e){return $CLJS.P1.g($CLJS.J.g(a,e),$CLJS.J.g(b,e))},c)});$CLJS.P1.m(null,$CLJS.nA,function(a,b){var c=$CLJS.E.g($CLJS.D(a),$CLJS.D(b));if(c)for(c=$CLJS.y(a),$CLJS.z(c),$CLJS.B(c),c=$CLJS.y(b),$CLJS.z(c),$CLJS.B(c),c=a,a=b;;){c=$CLJS.y(c);b=$CLJS.z(c);var d=$CLJS.B(c);c=b;b=d;a=$CLJS.y(a);d=$CLJS.z(a);a=$CLJS.B(a);c=$CLJS.P1.g(c,d);if($CLJS.n(c)){if(c=$CLJS.od(b))return c;c=b}else return c}else return c});
var B1=null;$CLJS.P1.m(null,$CLJS.UU,function(a,b){var c=B1;B1=new $CLJS.h(null,2,[R1,M1(a),$CLJS.uw,M1(b)],null);try{var d=$CLJS.kH($CLJS.P1,$CLJS.pA);return d.g?d.g(a,b):d.call(null,a,b)}finally{B1=c}});
$CLJS.P1.m(null,$CLJS.ZE,function(a,b){var c=$CLJS.I(a,0,null),d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.I(b,0,null),k=$CLJS.I(b,1,null),l=$CLJS.I(b,2,null);return(a=$CLJS.E.l(3,$CLJS.D(a),$CLJS.H([$CLJS.D(b)])))?(c=$CLJS.E.g(c,f))?(d=$CLJS.P1.g(d,k),$CLJS.n(d)?$CLJS.n(B1)?$CLJS.E.g(C1(new $CLJS.P(null,2,5,$CLJS.Q,[R1,e],null),bqa),C1(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uw,l],null),aqa)):$CLJS.E.g(e,l):d):c:a});
$CLJS.P1.m(null,$CLJS.Oh,function(a,b){if($CLJS.sd(a)){var c=$CLJS.kH($CLJS.P1,$CLJS.pA);return c.g?c.g(a,b):c.call(null,a,b)}return $CLJS.rd(a)?(c=$CLJS.kH($CLJS.P1,$CLJS.nA),c.g?c.g(a,b):c.call(null,a,b)):$CLJS.E.g(a,b)});
$CLJS.T1=function(){function a(f,k,l,m,t){var u=$CLJS.wV(l)?l:$CLJS.pV(l),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null),A=$CLJS.I(u,2,null),C=e.j?e.j(u,m,t):e.call(null,u,m,t);if($CLJS.n(C))return C;C=$CLJS.n(function(){var K=$CLJS.P1.g(v,$CLJS.ZE);return $CLJS.n(K)?$CLJS.zK.h(x):K}())?$CLJS.rG(function(K){var S=$CLJS.P1.g($CLJS.MJ.h(K),$CLJS.jK);return $CLJS.n(S)?$CLJS.P1.g($CLJS.U.h(K),$CLJS.zK.h(x)):S},m):null;if($CLJS.n(C))return C;if($CLJS.n($CLJS.n(f)?"number"===typeof A:f)&&(m=$CLJS.Be($CLJS.Vj.g($CLJS.Ci,
m)),$CLJS.n(m))){var G=$CLJS.wV(l)?$CLJS.N1(f,k,A):l;if($CLJS.n(G))return f=$CLJS.GD($CLJS.R.j(u,2,function(){var K=$CLJS.KY.h(G);return $CLJS.n(K)?K:$CLJS.U.h(G)}()),$CLJS.Qe($CLJS.mk,new $CLJS.h(null,1,[$CLJS.tA,$CLJS.Xi],null))),e.j?e.j(f,m,t):e.call(null,f,m,t)}return null}function b(f,k,l,m){return e.M?e.M(f,k,l,m,$CLJS.N):e.call(null,f,k,l,m,$CLJS.N)}function c(f,k,l){var m=$CLJS.I(f,0,null);$CLJS.I(f,1,null);var t=$CLJS.I(f,2,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.Q1);switch(m instanceof $CLJS.M?
m.S:null){case "aggregation":return $CLJS.rG(function(u){return $CLJS.E.g($CLJS.MJ.h(u),$CLJS.jK)&&$CLJS.E.g($CLJS.C0.h(u),t)},k);case "expression":case "field":switch(k="string"===typeof t?Upa(f,k):Vpa(f,k,l),$CLJS.D(k)){case 0:return null;case 1:return $CLJS.z(k);default:return $pa(f,k)}default:throw $CLJS.Uh("Unknown type of ref",new $CLJS.h(null,1,[$CLJS.W,f],null));}}function d(f,k){return e.j?e.j(f,k,$CLJS.N):e.call(null,f,k,$CLJS.N)}var e=null;e=function(f,k,l,m,t){switch(arguments.length){case 2:return d.call(this,
f,k);case 3:return c.call(this,f,k,l);case 4:return b.call(this,f,k,l,m);case 5:return a.call(this,f,k,l,m,t)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;e.v=b;e.M=a;return e}();
$CLJS.dqa=function(){function a(d,e,f,k){return $CLJS.T1.v(d,e,$CLJS.rY.j(d,e,f),k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();