var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var j7,k7,l7,ota,pta,qta,o7,p7,r7,s7,rta,sta,tta,u7,v7,w7,uta,vta;j7=function(a){var b=new $CLJS.h(null,3,[$CLJS.xD,$CLJS.p.h($CLJS.HD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.ki,$CLJS.zz($CLJS.ki,$CLJS.tA)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,b,$CLJS.zz($CLJS.oD,$CLJS.U)(a)],null)};k7=function(a){return $CLJS.R.j(a,$CLJS.Vs,$CLJS.B4)};l7=function(a,b){return $CLJS.E.g($CLJS.jD.h(a),$CLJS.Cl(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BD,$CLJS.iD,$CLJS.AD],null)))};
ota=function(a){return $CLJS.P0(a,new $CLJS.h(null,1,[$CLJS.BD,$CLJS.mh],null))};
pta=function(a,b){var c=$CLJS.Me(function(e){return $CLJS.rG(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KY,$CLJS.U],null));if($CLJS.n(c))return c;c=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",c))){var d=$CLJS.fD("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([a])),$CLJS.xh.l($CLJS.H([$CLJS.Wj.g($CLJS.KY,b)]))]));d instanceof Error?$CLJS.Kz("metabase.lib.field",c,$CLJS.xv(),d):$CLJS.Kz("metabase.lib.field",
c,$CLJS.xv.l($CLJS.H([d])),null)}return null};
qta=function(a,b,c){if($CLJS.n(m7))return null;var d=m7;m7=!0;try{var e=$CLJS.H1(a,b),f=$CLJS.n(e)?$CLJS.EV(a,e):$CLJS.EV(a,b),k=function(){var m=$CLJS.n7.h(f);if($CLJS.n(m))return m;m=$CLJS.Bz(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nU,$CLJS.BV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.NU.h(f);if($CLJS.n(u))return u;u=$CLJS.eO.h(f);if($CLJS.n(u))return u;u=$CLJS.ZO.h(f);return $CLJS.n(u)?u:$CLJS.RD.h(f)}())?$CLJS.X_.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",
m))){var t=$CLJS.fD("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.Kz("metabase.lib.field",m,$CLJS.xv(),t):$CLJS.Kz("metabase.lib.field",m,$CLJS.xv.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?pta(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.h3($CLJS.Nj.l(l,$CLJS.Ci,$CLJS.H([$CLJS.ZJ,$CLJS.NV,$CLJS.IV])),null),$CLJS.U,function(){var m=$CLJS.KY.h(l);return $CLJS.n(m)?m:$CLJS.U.h(l)}()),
$CLJS.MJ,$CLJS.vY):l:null}finally{m7=d}};
o7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.tO);var f=$CLJS.I(c,2,null);c=$CLJS.mk.l($CLJS.H([function(){var k=$CLJS.tA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.tA,k],null):null}(),function(){var k=$CLJS.zz($CLJS.ki,$CLJS.tA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.ki,k],null):null}(),function(){var k=$CLJS.tQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.NV,k],null):null}(),function(){var k=$CLJS.pF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.IV,k],null):null}(),$CLJS.Ad(f)?function(){var k=$CLJS.N1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.KJ,$CLJS.U,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.KJ,$CLJS.U,$CLJS.p.h(f)],null):function(){var k=qta(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.KJ,$CLJS.U,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.h3(c,d):c};
p7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.IV);return $CLJS.n($CLJS.n(b)?$CLJS.Cd($CLJS.oG,b):b)?$CLJS.Ti:$CLJS.zz($CLJS.ki,$CLJS.tA)(a)};
$CLJS.q7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.xD),l=$CLJS.J.g(f,$CLJS.tA),m=$CLJS.J.g(f,$CLJS.tQ),t=$CLJS.J.g(f,$CLJS.ki);e=$CLJS.J.g(f,$CLJS.tO);var u=$CLJS.J.g(f,$CLJS.EM),v=$CLJS.J.g(f,$CLJS.pF),x=$CLJS.mk.l;k=new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.KJ,$CLJS.C0,k],null);f=$CLJS.kD.h(f);a=$CLJS.n(f)?f:$CLJS.k_.j(a,b,d);c=x.call($CLJS.mk,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.kD,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.ki,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.tA,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.IV,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.NV,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.YJ,u):m;return $CLJS.n(e)?$CLJS.h3(u,e):u};r7=function(a,b,c){return $CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.tk.h(d),c)},a))?$CLJS.Wj.g(function(d){var e=$CLJS.Cd(d,b)?$CLJS.Nj.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.tk.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
s7=function(a){var b=$CLJS.n($CLJS.o1.h(a))?null:function(){var e=$CLJS.MJ.h(a),f=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.OY,null,$CLJS.VY,null,$CLJS.vY,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.mk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.xD,$CLJS.p.h($CLJS.HD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.ki,p7(a)],null),function(){var e=function(){var f=$CLJS.Ra(b);return f?(f=$CLJS.Ra($CLJS.YJ.h(a)))?(f=$CLJS.Tj.g($CLJS.GY,$CLJS.MJ.h(a)))?$CLJS.ZY.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.tO,e],null):null}(),function(){var e=$CLJS.h1(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.tO,e],null):null}(),function(){var e=$CLJS.IV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.pF,e],null):null}(),function(){var e=$CLJS.MV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.MV,e],null):null}(),function(){var e=$CLJS.NV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.tQ,e],null):null}(),function(){var e=$CLJS.YJ.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.EM,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.zz($CLJS.KY,$CLJS.U):$CLJS.zz($CLJS.Ci,$CLJS.U))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,c,d],null)};rta=function(a,b){return $CLJS.ff(function(c){return $CLJS.E.g($CLJS.MJ.h(c),$CLJS.EY)},$CLJS.X_.v(a,b,$CLJS.EV(a,b),new $CLJS.h(null,3,[$CLJS.E_,!1,$CLJS.K_,!0,$CLJS.A_,!1],null)))};sta=new $CLJS.M("lib","simple-name","lib/simple-name",-734346338);tta=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);
$CLJS.t7=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);u7=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);v7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.n7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);w7=new $CLJS.M("metabase.lib.field","simple-display-name","metabase.lib.field/simple-display-name",494797345);
uta=new $CLJS.M(null,"earliest","earliest",-1928154382);vta=new $CLJS.M(null,"latest","latest",24323665);$CLJS.Q0.m(null,$CLJS.jF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mh.h(b),$CLJS.P0(c,new $CLJS.h(null,2,[$CLJS.pF,$CLJS.mh,$CLJS.tQ,ota],null)),a],null)});
var m7=!1,x7=function x7(a,b){var d=$CLJS.Z0(a,v7.h(b));a=$CLJS.n(v7.h(d))?x7.g?x7.g(a,d):x7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.U),f=$CLJS.J.g(a,$CLJS.kD);return $CLJS.Xj.j($CLJS.R.j($CLJS.Xj.j($CLJS.R.j(b,sta,$CLJS.U.h(b)),$CLJS.U,function(k){return[$CLJS.p.h(e),".",$CLJS.p.h(k)].join("")}),w7,$CLJS.kD.h(b)),$CLJS.kD,function(k){return[$CLJS.p.h(f),": ",$CLJS.p.h(k)].join("")})};$CLJS.P_.m(null,$CLJS.KJ,function(a,b,c){return p7(c)});
$CLJS.P_.m(null,$CLJS.jF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.pF);$CLJS.I(c,2,null);c=o7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.IV,d):c;return $CLJS.R_.j(a,b,c)});$CLJS.S_.m(null,$CLJS.KJ,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);return $CLJS.R.j(a,$CLJS.U,b)});$CLJS.S_.m(null,$CLJS.jF,function(a,b,c){var d=o7(a,b,c);b=$CLJS.q7(a,b,d,c);return $CLJS.n(v7.h(b))?x7(a,b):b});
$CLJS.L_.m(null,$CLJS.KJ,function(a,b,c,d){function e(T){var da=$CLJS.g5(l,k);return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}function f(T){var da=$CLJS.cA($CLJS.Gz($CLJS.Xg(u),"-"," "));return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}var k=$CLJS.O(c),l=$CLJS.J.g(k,$CLJS.NV),m=$CLJS.J.g(k,$CLJS.YJ),t=$CLJS.J.g(k,$CLJS.ZJ),u=$CLJS.J.g(k,$CLJS.tk),v=$CLJS.J.g(k,$CLJS.ZY),x=$CLJS.J.g(k,w7),A=$CLJS.J.g(k,$CLJS.U),C=$CLJS.J.g(k,$CLJS.G5),G=$CLJS.J.g(k,
$CLJS.kD),K=$CLJS.J.g(k,v7),S=$CLJS.f1.g($CLJS.xA,A);c=function(){if($CLJS.n(x))return x;if($CLJS.n($CLJS.n(K)?null==G||$CLJS.E.g(G,S):K)){a:{var T=$CLJS.O(k);var da=$CLJS.J.g(T,$CLJS.kD);T=$CLJS.J.g(T,v7);for(da=new $CLJS.Vd(null,da,null,1,null);;)if($CLJS.n(T)){T=$CLJS.Z0(a,T);var oa=$CLJS.O(T);T=$CLJS.J.g(oa,$CLJS.kD);oa=$CLJS.J.g(oa,v7);da=$CLJS.Yd.g(da,T);T=oa}else break a}da=$CLJS.Le($CLJS.Sa,da)?$CLJS.hs(": ",da):null}else da=null;return $CLJS.n(da)?da:$CLJS.n(G)?G:"string"===typeof A?S:$CLJS.p.h(A)}();
var X=$CLJS.E.g(d,$CLJS.l_)&&-1==c.indexOf(" → ")?function(){if($CLJS.n(m)){var T=$CLJS.Z0(a,m);$CLJS.n(T)?T=$CLJS.d3($CLJS.kD.h($CLJS.n_.j(a,b,T))):(T=$CLJS.g1(a,t),T=$CLJS.k_.v(a,b,T,d))}else T=null;return $CLJS.n(T)?T:$CLJS.n(v)?v:$CLJS.h1(k)}():null;c=$CLJS.n(X)?[$CLJS.p.h(X)," → ",$CLJS.p.h(c)].join(""):c;return $CLJS.n(function(){var T=$CLJS.Tj.g(d,$CLJS.l_);return T?C:T}())?c:$CLJS.n($CLJS.n(u)?$CLJS.Tj.g(c,f(S)):u)?f(c):$CLJS.n($CLJS.n(l)?$CLJS.Tj.g(c,e(S)):l)?e(c):c});
$CLJS.L_.m(null,$CLJS.jF,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.tQ);var k=$CLJS.J.g(f,$CLJS.tO),l=$CLJS.J.g(f,$CLJS.pF);f=$CLJS.J.g(f,$CLJS.EM);$CLJS.I(c,2,null);c=o7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.ZY,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.tk,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.NV,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.YJ,f):e;return $CLJS.n(e)?$CLJS.k_.v(a,b,e,d):$CLJS.ID("[Unknown Field]")});
$CLJS.M_.m(null,$CLJS.KJ,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.U)});$CLJS.M_.m(null,$CLJS.jF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=o7(a,b,c);return $CLJS.n(c)?$CLJS.N_.j(a,b,c):"unknown_field"});
$CLJS.T_.m(null,$CLJS.KJ,function(a,b,c){return $CLJS.mk.l($CLJS.H([function(){var d=$CLJS.kH($CLJS.T_,$CLJS.Oh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.k_.j(a,b,c),$CLJS.m_,$CLJS.k_.v(a,b,c,$CLJS.l_)],null),$CLJS.E.g($CLJS.MJ.h(c),$CLJS.OY)?function(){var d=$CLJS.YY.h(c);return $CLJS.n(d)?(d=$CLJS.e_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.zj,new $CLJS.h(null,2,[$CLJS.U,$CLJS.U.h(d),$CLJS.kD,$CLJS.U.h(d)],null)],null):null):null}():null]))});
$CLJS.t0.m(null,$CLJS.jF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.pF.h(b)});$CLJS.t0.m(null,$CLJS.KJ,function(a){return $CLJS.IV.h(a)});
$CLJS.s0.m(null,$CLJS.jF,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Cd($CLJS.oG,b),e=$CLJS.qB($CLJS.MV,$CLJS.ki,$CLJS.tA)(c);c=$CLJS.R.l(c,$CLJS.pF,b,$CLJS.H([$CLJS.ki,d?$CLJS.Ti:e,$CLJS.MV,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,c,a],null)}b=$CLJS.MV.h(c);c=$CLJS.n(b)?$CLJS.Nj.g($CLJS.R.j(c,$CLJS.ki,b),$CLJS.MV):c;c=$CLJS.Nj.g(c,$CLJS.pF);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,c,a],null)});
$CLJS.s0.m(null,$CLJS.KJ,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.IV,b,$CLJS.H([$CLJS.MV,$CLJS.qB($CLJS.MV,$CLJS.ki,$CLJS.tA)(a)])):$CLJS.Nj.l(a,$CLJS.IV,$CLJS.H([$CLJS.MV]))});$CLJS.v0.m(null,$CLJS.jF,function(a,b,c){return $CLJS.w0.j(a,b,o7(a,b,c))});
$CLJS.v0.m(null,$CLJS.KJ,function(a,b,c){if($CLJS.Tj.g($CLJS.MJ.h(c),$CLJS.EY)){a=$CLJS.zz($CLJS.ki,$CLJS.tA)(c);b=null==c?null:$CLJS.SM.h(c);if(null==b)var d=null;else try{var e=$CLJS.Zi.h($CLJS.Mi.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,uta),l=$CLJS.J.g(f,vta),m=$CLJS.kpa.l($CLJS.H([$CLJS.q0.h(k),$CLJS.q0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Bq.g?$CLJS.Bq.g(1,m):$CLJS.Bq.call(null,1,m))?$CLJS.zk:$CLJS.n($CLJS.Bq.g?$CLJS.Bq.g(31,m):$CLJS.Bq.call(null,31,m))?$CLJS.Kk:$CLJS.n($CLJS.Bq.g?
$CLJS.Bq.g(365,m):$CLJS.Bq.call(null,365,m))?$CLJS.Oi:$CLJS.Ui}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.Dz(a,$CLJS.Zi)?$CLJS.vpa:$CLJS.Dz(a,$CLJS.vk)?$CLJS.upa:$CLJS.Dz(a,$CLJS.Lk)?$CLJS.tpa:$CLJS.xf;d=$CLJS.n(d)?r7(e,$CLJS.Oh,d):e;return $CLJS.n($CLJS.IV.h(c))?r7(d,$CLJS.s_,$CLJS.IV.h(c)):d}return $CLJS.xf});
$CLJS.d5.m(null,$CLJS.jF,function(a){var b=null==a?null:$CLJS.ED(a);b=null==b?null:$CLJS.tQ.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.i5,$CLJS.H([$CLJS.h5,function(c,d){return o7(c,d,a)}]))});$CLJS.d5.m(null,$CLJS.KJ,function(a){var b=null==a?null:$CLJS.NV.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.i5,$CLJS.H([$CLJS.h5,$CLJS.Pe(a)]))});$CLJS.j5.m(null,$CLJS.jF,function(a,b){return $CLJS.FD(a,$CLJS.DD,$CLJS.H([$CLJS.tQ,b]))});
$CLJS.j5.m(null,$CLJS.KJ,function(a,b){return $CLJS.DD(a,$CLJS.NV,b)});$CLJS.k5.m(null,$CLJS.jF,function(a,b,c){return $CLJS.l5.j(a,b,o7(a,b,c))});
$CLJS.k5.m(null,$CLJS.KJ,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.ki);var d=$CLJS.J.g(b,$CLJS.SM),e=$CLJS.J.g(b,$CLJS.kj);if($CLJS.Tj.g($CLJS.MJ.h(b),$CLJS.EY)){var f=function(){var m=null==a?null:$CLJS.Y0(a);m=null==m?null:$CLJS.dz.h(m);return null==m?null:$CLJS.Cd(m,$CLJS.tQ)}(),k=$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,$CLJS.gj],null)),l=$CLJS.e5(b);return function u(t){return new $CLJS.ie(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.vd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),
C=$CLJS.le(A);return function(){for(var K=0;;)if(K<A){var S=$CLJS.hd(x,K),X=C,T=S;S=l7(S,l)?$CLJS.R.j(T,$CLJS.s_,!0):T;X.add(S);K+=1}else return!0}()?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}var G=$CLJS.z(v);return $CLJS.ae(function(){var K=G;return l7(G,l)?$CLJS.R.j(K,$CLJS.s_,!0):K}(),u($CLJS.Hc(v)))}return null}},null,null)}($CLJS.Ra(function(){if($CLJS.n(f)){var t=$CLJS.ni.h(k);return $CLJS.n(t)?$CLJS.tj.h(k):t}return f}())?null:$CLJS.Dz(e,$CLJS.Vi)?$CLJS.Wj.g(k7,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.f5(),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.ID("Bin every 0.1 degrees"),$CLJS.jD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.AD,$CLJS.AD,.1],null)],null),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.ID("Bin every 1 degree"),$CLJS.jD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.AD,$CLJS.AD,1],null)],null),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.ID("Bin every 10 degrees"),$CLJS.jD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.AD,$CLJS.AD,10],null)],null),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.ID("Bin every 20 degrees"),$CLJS.jD,new $CLJS.h(null,
2,[$CLJS.BD,$CLJS.AD,$CLJS.AD,20],null)],null)],null)):$CLJS.Dz(c,$CLJS.gj)&&!$CLJS.Dz(e,$CLJS.$h)?$CLJS.Wj.g(k7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.f5(),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.ID("10 bins"),$CLJS.jD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.iD,$CLJS.iD,10],null)],null),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.ID("50 bins"),$CLJS.jD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.iD,$CLJS.iD,50],null)],null),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.ID("100 bins"),$CLJS.jD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.iD,$CLJS.iD,
100],null)],null)],null)):null)}return $CLJS.xf});$CLJS.nV.m(null,$CLJS.jF,function(a){return a});
$CLJS.nV.m(null,$CLJS.KJ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.MJ);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.xD,$CLJS.p.h($CLJS.HD()),$CLJS.ki,$CLJS.zz($CLJS.ki,$CLJS.tA)(a),$CLJS.zK,$CLJS.U.h(a)],null),a=$CLJS.C0.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,b,a],null);case "source/expressions":return j7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.oD.h(a))?j7(a):s7(a);default:return s7(a)}});
$CLJS.wta=function(){function a(e,f,k){k=$CLJS.Be($CLJS.Wj.g($CLJS.pV,k));var l=rta(e,f),m=$CLJS.Wf.j($CLJS.Sg,$CLJS.ul(function(t){return $CLJS.T1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.xf);m=$CLJS.Vj.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.cf.h($CLJS.pV),m):null;return $CLJS.PV.l(e,f,$CLJS.DD,$CLJS.H([$CLJS.RD,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.y7=function(){function a(d,e){return $CLJS.RD.h($CLJS.EV(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.xta=function(){function a(d,e){var f=$CLJS.X_.v(d,e,$CLJS.EV(d,e),new $CLJS.h(null,3,[$CLJS.E_,!1,$CLJS.K_,!1,$CLJS.A_,!1],null)),k=$CLJS.y7.g(d,e);return $CLJS.od(k)?$CLJS.Wj.g(function(l){return $CLJS.R.j(l,$CLJS.MY,!0)},f):$CLJS.f3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y(u7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.Yi,$CLJS.yY,$CLJS.cz],null));
$CLJS.Y(tta,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.t7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,u7],null)],null)],null));